<template>
  <v-container>
    <!-- notification area -->
    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <!-- loading screen -->
    <v-row v-if="loading" justify="center">
      <v-col xs="12" xl="8">
        <v-col>
          <h3 class="text-center">
            <v-progress-circular indeterminate class="ml-3" color="primary" />
            Please be patient, this may take a while.
          </h3>
        </v-col>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="8">
        <!-- header text -->
        <v-row>
          <v-col>
            <h1>List - Objective Templates</h1>

            <p class="grey--text">
              These are structured frameworks designed to guide us in defining
              clear, actionable goals. These templates typically consist of key
              components such as a concise statement of the objective,
              measurable outcomes, relevant timelines, and any necessary
              resources or milestones required for achievement.
            </p>
          </v-col>
        </v-row>

        <!-- table -->
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="objective_templates"
              :items-per-page="-1"
            >
              <template v-slot:[`item.type`]="{ item }">
                {{
                  item.type === "Service Ticket"
                    ? `${item.type} -  ${item.service_ticket_type}`
                    : item.type
                }}

                {{
                  item.service_ticket_type === "Other"
                    ? `- ${item.specified_service_ticket_type}`
                    : ""
                }}
              </template>

              <template v-slot:[`item.task_count`]="{ item }">
                {{ item.obj_tsk_templs.length }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-row>
                  <v-col class="d-flex align-center text-end">
                    <v-btn
                      icon
                      small
                      class="mr-5"
                      title="edit"
                      @click="
                        $router.push(
                          `/dashboard/admin/task_manager/objective/edit?id=${item.id}`
                        )
                      "
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      small
                      color="red"
                      title="delete"
                      @click="handle_delete_template(item.id)"
                    >
                      <v-icon>mdi-trash-can-outline </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- actions -->
        <v-row>
          <v-col>
            <v-btn @click="handle_create_template"> Create New Template </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";

export default {
  data: () => ({
    loading: false,
    error: null,
    errorMessage: "",

    headers: [
      { text: "Type", value: "type" },
      { text: "Subject", value: "subject" },
      { text: "Description", value: "description" },
      { text: "Task Count", value: "task_count" },
      { text: "", value: "actions", sortable: false },
    ],

    objective_templates: [],
  }),

  methods: {
    handle_create_template() {
      this.$router.push("/dashboard/admin/task_manager/objective/add");
    },

    async handle_delete_template(obj_id) {
      try {
        if (window.confirm("Are you sure you want to delete this template?")) {
          this.loading = true;

          const response = await API().post(
            `api/internal-admin/objective-template/delete`,
            { id: obj_id }
          );

          console.log(response);

          if (response.status === 200) {
            this.objective_templates = this.objective_templates.filter(
              (template) => template.id !== obj_id
            );
            this.loading = false;
          } else {
            this.error = true;
            this.errorMessage = "Something went wrong. Please try again later.";
            this.loading = false;
          }
        } else {
          console.log("cancelled");
          return;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
        this.loading = false;
      }
    },

    async get_objective_templates() {
      try {
        this.loading = true;

        const response = await API().get(
          "api/internal-admin/objective-template/read_many"
        );
        // console.log(response);

        if (response.status === 200) {
          this.objective_templates = response.data;
          this.loading = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong. Please try again later.";
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
        this.loading = false;
      }
    },
  },

  mounted() {
    this.get_objective_templates();
  },
};
</script>
